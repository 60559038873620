import React from 'react'
import { Link ,Outlet} from "react-router-dom";
//import logo from '../logo1.png';
import { useState, useEffect } from "react";
import http from '../http';
//import * as Constants from '../Constants';
const Dotenv = require("../Constants");



export default function Navbar() {
  const baseURL=Dotenv.BACKENDURL;
//console.log(Dotenv.baseURL1)
  const [menus, setMenus] = useState([])
  useEffect(()=>{
    async function getAllMenu(){
      try {
        const menus = await http.get("/api/v1/menuList")
       // console.log(menus.data)
        setMenus(menus.data)
      } catch (error) {
        console.log(error)
      }
    }
    getAllMenu()
  }, [])


  const [footerlogo, setfooterlogo] = useState([])
 
    useEffect(()=>{
      getSiteSetting()
    }, [])

    const getSiteSetting = () => {
      try {
        http.get('/api/v1/siteSettings').then(siteSettings=>{
         
          setfooterlogo(siteSettings.data.image);
         
        })
      } catch (error) {
        console.log(error)
      }

      
  }

  return (
    <div className="body">
      
       <div className="container">
      <nav className="navbar fixed-top navbar-expand-lg bg-light">
        <div className="container">
           <Link className="navbar-brand" to="/">
   
              <img src={baseURL+'/site/image/'+footerlogo } className="w-100"/>

            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {
                menus.map((menu, i)=>{
                    return (
                      <li className={`${menu.sub_menu != '' ? "nav-item dropdown" : "nav-item"}`}>
                          <Link key={menu.id} to={menu.custom_link} className={`${menu.sub_menu != '' ? "nav-link dropdown-toggle" : "nav-link"}`} role={`${menu.sub_menu != '' ? "button" : ""}`} data-bs-toggle={`${menu.sub_menu != '' ? "dropdown" : ""}`} aria-expanded="false">{menu.custom_label}

                         {/** 
                          *  if(isLoggedIn){
                            return <button>Logout</button>
                          } else{
                            return <button>Login</button>
                          }
                        }
                          * 
                          * 
                         */} 
                         
                          <ul className="dropdown-menu">
                            
                            {
                                menu.sub_menu && menu.sub_menu.map((sub_menu1, i)=>{
                                  return ( <li> 
                                    <Link key={sub_menu1.id} to={sub_menu1.custom_link} className="dropdown-item">{sub_menu1.custom_label}</Link>
                                    </li>
                                  )
                                })
                            }   
                            
                            </ul>
                           
                        </Link>
                      </li> 
                    
                    )
                })
              }    
              
                {/*<li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Services
                  </a>
                  <ul className="dropdown-menu">
                      <li> 
                      <Link className="dropdown-item" to="services/web-development">Website Development</Link>
                      </li>
                      <li> 
                      <Link className="dropdown-item" to="services/web-design">Website Design</Link>
                      </li>
                      <li> 
                      <Link className="dropdown-item" to="services/app-development">Mobile App Development</Link>
                      </li>
                  </ul>
            </li>*/}
               
  
            </ul>
            
            </div>
        </div>
        </nav>
        </div>
        <Outlet />
    </div>
    
  )
}
