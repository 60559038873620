import React from 'react'
import { Link ,Outlet} from "react-router-dom";
import { useState, useEffect } from "react";
import Parser from 'html-react-parser';

import http from '../http';
const Dotenv = require("../Constants");

export default function Slider() {

    const baseURL=Dotenv.BACKENDURL;
    const [sliders, setSliders] = useState([])
      useEffect(()=>{
        getAllSliders()
      }, [])
  
      const getAllSliders = () => {
        try {
          http.get('/api/v1/sliderList').then(sliders=>{
            setSliders(sliders.data);
          })
        } catch (error) {
          console.log(error)
        }
  
        
    }

  return (
    <div>
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="false">
        <div className="carousel-indicators">
             {
                sliders.map((slide,i) => {
                return( 
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={i} className={`  ${i == '0' ? "active" : ""}`} aria-current="true" aria-label="Slide 1"></button>
                )


                })
            }
        </div>
        <div className="carousel-inner">

                {
                sliders.map((slide,i) => {
                return( 
                    
                    <div className={`carousel-item  ${i == '0' ? "active" : ""}`} id={i}>
                    <img src={baseURL+'/' + slide.slide_image} className="d-block w-100" alt={slide.alt_tag} />
                    <div className="carousel-caption banner-caption">
                    <h5><Link className="dropdown-item" to={slide.slide_url}>{slide.title}</Link></h5>
                        <p>{Parser(slide.description)}</p>
                    </div>
                    </div>
                )
                })
            }


        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
        </div>


    </div>
  )
}
