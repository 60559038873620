import React from 'react'

function WebDesign() {
  return (
    <div className="pt-5 headerBackground">
       <section class="container text-center pt-5">
            <div class="row py-lg-5">
            <div class="col-lg-6 col-md-8 mx-auto">
                <h1 class="fw-light">Website Design</h1>
                 {/*<p class="lead text-muted">Something short and leading about the collection below—its contents, the creator, etc. Make it short and sweet, but not too short so folks don’t simply skip over it entirely.</p>*/}
            </div>
            </div>
        </section>
        <div class="album py-5 bg-light">
                <div class="container">
                    <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div class="col-10 col-sm-8 col-lg-6">
                        <img src="../images/webdes.png" alt="Logo"/>
                        </div>
                        <div class="col-lg-6">
                        <p>HariWeb Techno is Website Design India based company and accepting challenges to deploy complexity to create simplicity in UI/UX design. We are one of the best Web Designer Company in India to serve UK ,US and INDIA based projects which is increasing its productivity at cost effective prices. Web Design India means to say that Website Designing Companies in India i.e. HariWeb Techno. The sense of profitability, durability, unmatched designing and more, can be leaded by your today’s choice of letting outsourcing partners as us. We make you sure about the on-time delivery, better unarguable workability and ease along the way. Designing is leaded by graphics and attractive and powerful contents which are our core strength. We at HariWeb Techno always prefer our creditability on meeting standards of your business need. We are influencing opinions and creating waves to beat the thought of thinking small. But, whenever you think big, we help it bigger. With an enthusiasm of standing ultimate Web Design India we allow for an easy, clear and precise navigation through the various stages of creating customer’s attention that would result in an enhanced user experience. All these appreciations achieved by us are due to the best use of HTML5, CSS3 & CSS4 modules to develop our still design concepts into live and interactive websites. You not only get our Website Design Service but you will be getting the right idea about the product and guidance as per your specific locations. We showcase our strength by using graphic based web design to reduce/alter dependency on flash website design.
                    </p>
                    </div>
                    </div>

                
                    </div>
                </div>


                <div class="album py-5 expert_logos">
                    <div class="container">
                        <div class="row">
                            <div className="col-sm-12 text-center mb-5"><h2>We are experts in</h2></div>
                            <div class="col-sm-4">
                                <div class="box_logo">
                                <img src={process.env.PUBLIC_URL + "/images/html.png"} alt="ASP.Net" />

                                </div>
                            </div>

                            <div class="col-sm-4">
                                <div class="box_logo">
                                    <img src={process.env.PUBLIC_URL + "/images/css.png"} alt="PHP"/>
                                </div>
                            </div>

                            <div class="col-sm-4">
                                <div class="box_logo">
                                    <img src={process.env.PUBLIC_URL + "/images/bootstrap.png"} alt="Node.js"/>
                                </div>
                            </div>

                            <div class="col-sm-4">
                                <div class="box_logo">
                                    <img src={process.env.PUBLIC_URL + "/images/js.png"} alt="Python"/>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="box_logo">
                                    <img src={process.env.PUBLIC_URL + "/images/wordpress.png"} alt="WordPress"/>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="box_logo">
                                    <img src={process.env.PUBLIC_URL + "/images/photoshop.png"} alt="Angular"/>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="box_logo">
                                    <img src={process.env.PUBLIC_URL + "/images/illustrator.png"} alt="React.js"/>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="box_logo">
                                    <img src={process.env.PUBLIC_URL + "/images/xd.png"} alt="Vue.js"/>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="box_logo">
                                    <img src={process.env.PUBLIC_URL + "/images/indesign.png"} alt="Shopify"/>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="box_logo">
                                    <img src={process.env.PUBLIC_URL + "/images/sketch.png"} alt="Magento"/>
                                </div>
                            </div>

                            <div class="col-sm-4">
                                <div class="box_logo">
                                    <img src={process.env.PUBLIC_URL + "/images/figma.png"} alt="WooCommerce"/>
                                </div>
                            </div>

                            
                        </div>
                        </div>
                    </div>
                    
            </div>  
  )
}

export default WebDesign
