import React from 'react'

function AppDevelopment() {
  return (
    <div className="pt-5 headerBackground">
       <section class="container text-center headerBackground pt-5">
            <div class="row py-lg-5">
            <div class="col-lg-6 col-md-8 mx-auto">
                <h1 class="fw-light">Mobile App Development</h1>
                 {/*<p class="lead text-muted">Something short and leading about the collection below—its contents, the creator, etc. Make it short and sweet, but not too short so folks don’t simply skip over it entirely.</p>*/}
            </div>
            </div>
        </section>
        <div class="album py-5 bg-light">
                <div class="container">
                    <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div class="col-10 col-sm-8 col-lg-6">
                        <img src="../images/appdev.png"/>
                        </div>
                        <div class="col-lg-6">
                        <p>We at HariWeb Techno are dedicated to serve quality Mobile App Development with ability of creating app on any platform which supports multiple languages. We better know that Mobile apps have become a critical part of today’s digital marketing trends. Such app provides rich content on the small screen by creating and enriching handy brand experience for you and your business. Our highly skilled team of Mobile App Developers has all those mobile experiences that were trending before, are trending today and will be trending tomorrow which were/are/will be Native (IOS & Android), Web or Hybrid mode applications. Destructing the entire barriers of traditional approach, we develop eye catching UI/UX to decorate your business by fetching vast traffic towards your products/brand/name & fame. Not only that, user satisfaction is our inbuilt culture of our strategic decision/planning who believes in complete transparency and thus, HariWeb Techno can proudly offer you a detailed quote absolutely free.</p>
                    </div>
                    </div>

                
                    </div>
                </div>


                <div class="album py-5 expert_logos">
                    <div class="container">
                        <div class="row">
                            <div className="col-sm-12 text-center mb-5"><h2>We are experts in</h2></div>
                            <div class="col-sm-4">
                                <div class="box_logo">
                                <img src={process.env.PUBLIC_URL + "/images/android.png"} alt="ASP.Net" />

                                </div>
                            </div>

                            <div class="col-sm-4">
                                <div class="box_logo">
                                    <img src={process.env.PUBLIC_URL + "/images/ios.png"} alt="PHP"/>
                                </div>
                            </div>

                            <div class="col-sm-4">
                                <div class="box_logo">
                                    <img src={process.env.PUBLIC_URL + "/images/flutter.png"} alt="Node.js"/>
                                </div>
                            </div>


                            
                        </div>
                        </div>
                    </div>
                    
            </div>  
  )
}

export default AppDevelopment
