import React from 'react'

function WebDevelopment() {
  return (
    <div className="pt-5 headerBackground">
       <section class="container text-center pt-5">
         <div class="row py-lg-5">
         <div class="col-lg-6 col-md-8 mx-auto">
             <h1 class="fw-light">Website Development</h1>
              {/*<p class="lead text-muted">Something short and leading about the collection below—its contents, the creator, etc. Make it short and sweet, but not too short so folks don’t simply skip over it entirely.</p>*/}
         </div>
         </div>
     </section>
     <div class="album py-5 bg-light">
            <div class="container">
                <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                <div class="col-10 col-sm-8 col-lg-6">
                    <img src="../images/webdev.png" alt="Logo"/>
                    </div>
                    <div class="col-lg-6">
                    <p>Website Development India summarizes all IT companies like as HariWeb Techno. We at HariWeb Techno work for smart website development in India and emphasize quality of code by practicing the latest techniques and keep our websites updated to the international standards set to W3C. We always take care of our client’s and end-users’ custom needs to serve with specialized development, integrated UI/UX and deploy web as well as bespoke enterprise grade solutions. By leveraging dense understanding of social, Mobile, Analytics and Cloud (SMAC), HariWeb Techno delivers best in class web development solution. Apart from all we ensure that the products created by us, also incorporate highest quality display responsiveness and content management system which allows us for custom website development service requests and hence, help our customer to find for the best digital solution to the clients. With well qualified and experienced team of developer we will help you to build brands and generate traffic, leads and sales through your website. Thus, no need to worry about your business or e-trading rather we will be taking care of your whole burdens and will make you feel great. About 100s of satisfied customers from all around the globe are appreciating our unmatched website development techniques which have been tailoring your web needs for last one and half years.
                </p>
                </div>
                </div>

               
                </div>
            </div>


            <div class="album py-5 expert_logos">
                <div class="container">
                    <div class="row">
                        <div className="col-sm-12 text-center mb-5"><h2>We are experts in</h2></div>
                        <div class="col-sm-4">
                            <div class="box_logo">
                            <img src={process.env.PUBLIC_URL + "/images/asp-dot-net.png"} alt="ASP.Net" />

                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="box_logo">
                                <img src={process.env.PUBLIC_URL + "/images/php.png"} alt="PHP"/>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="box_logo">
                                <img src={process.env.PUBLIC_URL + "/images/node.png"} alt="Node.js"/>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="box_logo">
                                <img src={process.env.PUBLIC_URL + "/images/python.png"} alt="Python"/>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="box_logo">
                                <img src={process.env.PUBLIC_URL + "/images/wordpress.png"} alt="WordPress"/>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="box_logo">
                                <img src={process.env.PUBLIC_URL + "/images/angular.png"} alt="Angular"/>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="box_logo">
                                <img src={process.env.PUBLIC_URL + "/images/reactjs.png"} alt="React.js"/>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="box_logo">
                                <img src={process.env.PUBLIC_URL + "/images/vuejs.png"} alt="Vue.js"/>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="box_logo">
                                <img src={process.env.PUBLIC_URL + "/images/shopify.png"} alt="Shopify"/>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="box_logo">
                                <img src={process.env.PUBLIC_URL + "/images/magento.png"} alt="Magento"/>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="box_logo">
                                <img src={process.env.PUBLIC_URL + "/images/woocommerce.png"} alt="WooCommerce"/>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="box_logo">
                                <img src={process.env.PUBLIC_URL + "/images/opencart.png"} alt="OpenCart"/>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="box_logo">
                                <img src={process.env.PUBLIC_URL + "/images/sqlserver.png"} alt="SQL Server"/>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="box_logo">
                                <img src={process.env.PUBLIC_URL + "/images/mysql.png"} alt="MySQL"/>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="box_logo">
                                <img src={process.env.PUBLIC_URL + "/images/postgresql.png"} alt="PostgreSQL"/>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                
        </div>  
  
  )
}

export default WebDevelopment
