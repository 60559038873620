import React from 'react'
import { useLocation} from "react-router-dom";
import ReactDOM from 'react-dom/client';

import { useState, useEffect } from "react";
import Parser from 'html-react-parser';

import http from '../../http';
const Dotenv = require("../../Constants");

function Detail() {
  
  let location = useLocation();
  const newString = location.pathname.slice(6)

  const baseURL=Dotenv.BACKENDURL;
  const [slidersLoop, setSliders] = useState([])
  const [firstSlide, setfirstSlide] = useState([])
  const googleUrl = "https://www.google.com";

  const [iframSrc, setIframeUrl] = useState(googleUrl);

    useEffect(()=>{  getAllSliders() }, [])
   // useEffect(()=>{  handleClick() }, [])

    const handleClick = (event) => {
      var attribute = event;
      const removeId = event.target.dataset.remove;

      try {
        http.get('/api/v1/tutorialsDetails?id='+removeId).then(sliders2=>{
          
          if(sliders2.data[0]){  
            if(sliders2.data[0].video_link){ 
             setIframeUrl(sliders2.data[0].video_link)
            }
            ////  Append content after Ajax
           const container = document.getElementById('description');
           const description = ReactDOM.createRoot(container);
           const myelement =  sliders2.data[0].description;
           console.log(myelement)
           description.render(<div dangerouslySetInnerHTML={{ __html: myelement }}></div>);
          
          }else{
           // setfirstSlide('');
          }
          
         //const firstSlide = ;
        })
      } catch (error) {
        console.log(error)
      }
      
      
      
  }
    const getAllSliders = () => {
      try {
        http.get('/api/v1/tutorialsList?slug='+newString).then(sliders=>{
          
          setSliders(sliders.data);
          if(sliders.data[0]){  
            setfirstSlide(sliders.data[0]);

            ////  Append content after Ajax
          
           setIframeUrl(sliders.data[0].video_link)
           const container = document.getElementById('description');
           const description = ReactDOM.createRoot(container);
           const myelement =  sliders.data[0].description;
           description.render(<div dangerouslySetInnerHTML={{ __html: myelement }}></div>);
            
          }else{
            setfirstSlide('');
          }
          
        })
      } catch (error) {
        console.log(error)
      }
      
       
      
  }

  return (
    <>
    <div className="pt-5 headerBackground">
      
        <div className="album py-5 bg-light">
            <div className="container-fluid">
      <div className="row">
        <div className="col-sm-6">
          
        
        <iframe id="videoSrc" width="100%" height="650"
        src={iframSrc}>
        </iframe>

        </div>
        <div className="col-sm-6">
            <h2>{newString.toUpperCase()}</h2>
            <div className="item_list">
           
            
            {
                slidersLoop.map((slide,i) => {
                return( 
                    <div className="item" onClick={handleClick} data-remove={slide.id}>{slide.title}</div>
                )
                })
            }
              
            
            </div>
        </div>
      </div>
      <div className="container-fluid pt-2">
      <div className="row">
      <div className="col-sm-6">
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
  <li className="nav-item" role="presentation">
    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Overview</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Comment</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Downloads</button>
  </li>
</ul>
<div className="tab-content" id="pills-tabContent">
  <div className="tab-pane fade show active card" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
  
    <div id="description">
        <div dangerouslySetInnerHTML={{ __html: firstSlide.description }}></div>
    </div>
      
  </div>
  <div className="tab-pane fade card" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
   
    No Comments

  <div className="containera d-none">
			<h2>Add Comments/Questions</h2>  
  <textarea placeholder="Use '@' to mention other users in the comment! i.e @username" className="mb-2 mt-2 bg-gray-200__input form-control"></textarea>
  <button className="btn btn-info custom_sub_btn mb-4 mt-2">Comment</button>
        <div className="card">
       <div className="row">
         <div className="col-md-12">
           <h3 className="text-center mb-5">
             Nested comment section
           </h3>
           <div className="row">
             <div className="col-md-12">
               <div className="media">
                 <img className="mr-3 rounded-circle" alt="Bootstrap Media Preview" src="https://i.imgur.com/stD0Q19.jpg" />
                 <div className="media-body">
                   <div className="row">
                    <div className="col-8 d-flex">
                       <h5>Maria Smantha</h5>
                     <span>- 2 hours ago</span>
                    </div>
                    
                    <div className="col-4">
                    
                    <div className="pull-right reply">
                    
                   <a href="#"><span><i className="fa fa-reply"></i> reply</span></a>
                    
                    </div>
                    
                    </div>
                   </div>		
                   
                   It is a long established fact that a reader will be distracted by the readable content of a page.
                                         
                   <div className="media mt-4">
                      <a className="pr-3" href="#"><img className="rounded-circle" alt="Bootstrap Media Another Preview" src="https://i.imgur.com/xELPaag.jpg" /></a>
                     <div className="media-body">
                         
                       <div className="row">
                    <div className="col-12 d-flex">
                       <h5>Simona Disa</h5>
                     <span>- 3 hours ago</span>
                    </div>
                    
                   
                   </div>

                       letters, as opposed to using 'Content here, content here', making it look like readable English.
                     </div>
                   </div>
                   
                   <div className="media mt-3">
                      <a className="pr-3" href="#"><img className="rounded-circle" alt="Bootstrap Media Another Preview" src="https://i.imgur.com/nAcoHRf.jpg" /></a>
                     <div className="media-body">
                         <div className="row">
                    <div className="col-12 d-flex">
                       <h5>John Smith</h5>
                     <span>- 4 hours ago</span>
                    </div>
                    
                   
                   </div>
                   
                       the majority have suffered alteration in some form, by injected humour, or randomised words.
                                                 
                     </div>
                   </div>
                 </div>
               </div>
               
               
               
               
               <div className="media mt-4">
                 <img className="mr-3 rounded-circle" alt="Bootstrap Media Preview" src="https://i.imgur.com/xELPaag.jpg" />
                 <div className="media-body">
                   <div className="row">
                    <div className="col-8 d-flex">
                       <h5>Shad f</h5>
                     <span>- 2 hours ago</span>
                    </div>
                    
                    <div className="col-4">
                    
                    <div className="pull-right reply">
                    
                    <a href="#"><span><i className="fa fa-reply"></i> reply</span></a>
                    
                    </div>
                    
                    </div>
                   </div>		
                   
                   The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33.
                   <div className="media mt-4">
                      <a className="pr-3" href="#"><img className="rounded-circle" alt="Bootstrap Media Another Preview" src="https://i.imgur.com/nUNhspp.jpg" /></a>
                     <div className="media-body">
                         
                       <div className="row">
                    <div className="col-12 d-flex">
                       <h5>Andy flowe</h5>
                     <span>- 5 hours ago</span>
                    </div>
                    
                    
                   </div>

                       Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis.
                     </div>
                   </div>
                   
                   <div className="media mt-3">
                      <a className="pr-3" href="#"><img className="rounded-circle" alt="Bootstrap Media Another Preview" src="https://i.imgur.com/HjKTNkG.jpg" /></a>
                     <div className="media-body">
                         <div className="row">
                    <div className="col-12 d-flex">
                       <h5>Simp f</h5>
                     <span>- 5 hours ago</span>
                    </div>
                    
                   
                   </div>
                   
                       a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur
                     </div>
                   </div>
                                         
                                         
                                         <div className="media mt-3">
                      <a className="pr-3" href="#"><img className="rounded-circle" alt="Bootstrap Media Another Preview" src="https://i.imgur.com/nAcoHRf.jpg" /></a>
                     <div className="media-body">
                         <div className="row">
                    <div className="col-12 d-flex">
                       <h5>John Smith</h5>
                     <span>- 4 hours ago</span>
                    </div>
                    
                   
                   </div>
                   
                       the majority have suffered alteration in some form, by injected humour, or randomised words.
                                                 
                     </div>
                   </div>
                 </div>
               </div>
               
             </div>
           </div>
         </div>
       </div>
       </div>
     </div>

</div>
  <div className="tab-pane fade card" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
    Nothing to Download
  <div className="d-none">

  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.


  </div>
  </div>
</div>

      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
    </>
  )
}

export default Detail
