import React from 'react'
import Navbar from '../Navbar'
import { Link } from 'react-router-dom'

import { useState, useEffect } from "react";
import Parser from 'html-react-parser';

import http from '../../http';
const Dotenv = require("../../Constants");

function Blog() {

    const baseURL=Dotenv.BACKENDURL;
    const [sliders, setSliders] = useState([])
      useEffect(()=>{
        getAllSliders()
      }, [])
  
      const getAllSliders = () => {
        try {
          http.get('/api/v1/tutorialsCategoryList').then(sliders=>{
            setSliders(sliders.data);
          })
        } catch (error) {
          console.log(error)
        }
  
        
    }

  return (
    <div className="pt-5 headerBackground">
       <section className="container text-center headerBackground pt-5">
            <div className="row py-lg-5">
            <div className="col-lg-6 col-md-8 mx-auto">
                <h1 className="fw-light">Tutorials</h1>
                 {/*<p className="lead text-muted">Something short and leading about the collection below—its contents, the creator, etc. Make it short and sweet, but not too short so folks don’t simply skip over it entirely.</p>*/}
            </div>
            </div>
        </section>
        <div className="album py-5 bg-light">
            <div className="container">
           {/* <div className="row" style={{"min-height":"200px"}}>
                <div className="col-sm-12 text-center"><h2>Coming Soon</h2></div>
            </div>*/}
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                {
                    sliders.map((slide,i) => {
                    return( 
                        <>
                       
                        <div className="col">
                            <div className="card shadow-sm">
                                <img src={baseURL+'/' + slide.image}/>
                                <div className="card-body">
                                <p className="card-text text-center">{slide.title}</p>
                                <div className="align-items-center btn_div">
                                    <Link className="btn btn-sm btn-outline-secondary" to={slide.slug}>Start Learing</Link>
                                </div>
                                </div>
                            </div>
                        </div>
                        </>
                    )


                    })
                }
                
               
               
                {/*<div className="col">
                <div className="card shadow-sm">
                    <svg className="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"></rect><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>

                    <div className="card-body">
                    <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                        <button type="button" className="btn btn-sm btn-outline-secondary">View</button>
                        <button type="button" className="btn btn-sm btn-outline-secondary">Edit</button>
                        </div>
                        <small className="text-muted">9 mins</small>
                    </div>
                    </div>
                </div>
  </div>**/}

            </div>
            </div>
        </div>
       
    </div>
  )
}

export default Blog
