import React, {useState} from 'react'
import Swal from 'sweetalert2';
import http from "../../http";
const Dotenv = require("../../Constants");

function Contact() {
    const [inputField , setInputField] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    })
    const formData = {};
    const inputsHandler = (e) =>{
        
        const { name, value } = e.target;
       setInputField((prevState) => ({
         ...prevState,
         [name]: value,
       }));
    }
   // const navigate = useNavigate();


    const submitForm = (e) =>{
        const formData = ({
            name: inputField.name,
            email: inputField.email,
            phone_number:inputField.phone,
            subject:inputField.subject,
            message:inputField.message
          });
          
        e.preventDefault();


        http.post(`/api/v1/applicationPost`, formData).then(({data})=>{
            const succ  = JSON.stringify(data.success);
            console.log(succ)
            if(succ=="false"){
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: JSON.stringify(data.error),
                    })
             }else{
                Swal.fire({
                    icon: 'success',
                    title: 'Message Sent Successfully'
                  })
             }
          }).catch(({response})=>{
                console.log(response)
            if(response===422){
               Swal.fire({
                   icon: 'error',
                   title: '',
                   text: JSON.stringify(response.data.message),
                   })
            }else{
               Swal.fire({
                   icon: 'error',
                   title: 'Ooops, something went wrong <br><br>You can mail me your queries on below mail ID <br>kumar.vishal437@gmail.com',
                   text: '',
                   })
            }
          })

       /* http.post('/api/v1/applicationPost',formData).then((res)=>{
            navigate('/');
        })*/
    }
  return (
    <div className="pt-5 headerBackground">
       <section class=" text-center container pt-5">
            <div class="row py-lg-5">
            <div class="col-lg-6 col-md-8 mx-auto">
                <h1 class="fw-light">Contact Us</h1>
                {/*<p class="lead text-muted">Something short and leading about the collection below—its contents, the creator, etc. Make it short and sweet, but not too short so folks don’t simply skip over it entirely.</p>*/}
               
            </div>
            </div>
        </section>
        <div class="album py-5 bg-light">
            <div class="container">
                <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div class="col-10 col-sm-8 col-lg-6">
                        <h2 className="featurette-heading fw-normal lh-1">DROP US A MESSAGE.</h2>
                
                        <form onSubmit={submitForm}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group pt-3">
                                <input type="text" className="form-control" placeholder="Name" name="name" onChange={inputsHandler} value={inputField.name} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group pt-3">
                                <input type="email" className="form-control"  name="email" placeholder="Email" onChange={inputsHandler} value={inputField.email}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group pt-3">
                                <input type="text" className="form-control" name="phone" placeholder="Phone" onChange={inputsHandler} value={inputField.phone}/>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group pt-3">
                                <input type="text" className="form-control" name="subject"  placeholder="Subject" onChange={inputsHandler} value={inputField.subject}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group pt-3">
                               <textarea name="message" id="" cols="30" rows="4" className="form-control"   placeholder="Message" onChange={inputsHandler} value={inputField.message}></textarea>
                            </div>
                        </div>
                      
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group pt-3  pb-3">
                              <input type="submit" className="btn btn-info custom_sub_btn"/>
                            </div>
                        </div>
                      
                    </div>
               </form>
                    </div>
                    <div class="col-lg-6">
                       {/* <h1 class="display-5 fw-bold lh-1 mb-3">Responsive left-aligned hero with image</h1> */} 
                        <p class="lead">What we need from you when we quote each project individually and carefully to protect you.</p>
                        <ul><li>As much detail as you can provide.</li><li>Specifics as to your web site concept.</li><li>A general idea of your web project budget.</li><li>Programming needs.</li><li>Design color schemes</li><li>Logo ideas you have.</li><li>Keywords or phrases for your web site.</li><li>What type of host do you prefer, NT or Linux.</li><li>Programming languages you would like us to use.</li></ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact
