import logo from './logo.svg';
import React, { Component } from 'react';

import './App.css';
import { BrowserRouter, Routes, Route  } from "react-router-dom";
import Navbar from './component/Navbar';
import Slider from './component/Slider';
import Home from './component/pages/Home';
import Footer from './component/Footer';
import Blog from './component/pages/Blog';
import About from './component/pages/About';
import Contact from './component/pages/Contact';
import WebDevelopment from './component/pages/WebDevelopment';
import WebDesign from './component/pages/WebDesign';
import AppDevelopment from './component/pages/AppDevelopment';
import Details from './component/pages/Detail';

function App() {
  
  return (
    <>
    
      <Routes>
        
          <Route path="/" element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path="blog" element={<Blog/>} />
          <Route path="about-us" element={<About />} />
          <Route path="about" element={<About />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="contact" element={<Contact />} />

          <Route path="blog/*" element={<Details />} />
          <Route path="services/web-development" element={<WebDevelopment/>} />
          <Route path="services/web-design" element={<WebDesign/>} />
          <Route path="services/app-development" element={<AppDevelopment/>} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
   
   
     
     
      <Footer/>
    </>
  );
}

export default App;
