import React , {useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import Slider from '../Slider'
import Swal from 'sweetalert2';
//import axios from 'axios';

import http from '../../http';
import Parser from 'html-react-parser';
const Dotenv = require("../../Constants");

//import { useNavigate } from "react-router-dom";



export default function Home() {


    const baseURL=Dotenv.BACKENDURL;
    
    const [services, setServices] = useState([])
      useEffect(()=>{
        getAllServices()
      }, [])
  
      const getAllServices = () => {
        try {
          http.get('/api/v1/serviceList').then(services=>{
            setServices(services.data);
          })
        } catch (error) {
          console.log(error)
        }
  
        
    }

    const [portfolioList, setportfolioList] = useState([])
    useEffect(()=>{
        getAllPortfolioList()
    }, [])

    const getAllPortfolioList = () => {
      try {
        http.get('/api/v1/portfolioList').then(portfolioList=>{
          setportfolioList(portfolioList.data);
        })
      } catch (error) {
        console.log(error)
      }

      
  }
    
    const [inputField , setInputField] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    })
    const formData = {};
    const inputsHandler = (e) =>{
        
        const { name, value } = e.target;
       setInputField((prevState) => ({
         ...prevState,
         [name]: value,
       }));
    }
   // const navigate = useNavigate();


    const submitForm = (e) =>{
        const formData = ({
            name: inputField.name,
            email: inputField.email,
            phone_number:inputField.phone,
            subject:inputField.subject,
            message:inputField.message
          });
          
        e.preventDefault();


        http.post(`/api/v1/applicationPost`, formData).then(({data})=>{
            const succ  = JSON.stringify(data.success);
            console.log(succ)
            if(succ=="false"){
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: JSON.stringify(data.error),
                    })
             }else{
                Swal.fire({
                    icon: 'success',
                    title: 'Message Sent Successfully'
                  })
             }
          }).catch(({response})=>{
                console.log(response)
            if(response===422){
               Swal.fire({
                   icon: 'error',
                   title: '',
                   text: JSON.stringify(response.data.message),
                   })
            }else{
               Swal.fire({
                   icon: 'error',
                   title: 'Ooops, something went wrong <br><br>You can mail me your queries on below mail ID <br>kumar.vishal437@gmail.com',
                   text: '',
                   })
            }
          })

       /* http.post('/api/v1/applicationPost',formData).then((res)=>{
            navigate('/');
        })*/
    }
   /* const submitForm = (e) => {
        e.preventDefault();
      
        
        const formData = ({
            name: inputField.name,
            email: inputField.email,
            phone:inputField.phone,
            subject:inputField.subject,
            message:inputField.message
          });
          
          http.post(`/api/v1/applicationPost`, formData).then(({data})=>{
         Swal.fire({
            icon: 'success',
            title: 'Message Sent Successfully'
          })
      
       }).catch(({response})=>{
      
         if(response===422){
            Swal.fire({
                icon: 'error',
                title: '',
                text: JSON.stringify(response.data.message),
                })
         }else{
            Swal.fire({
                icon: 'error',
                title: 'Ooops, something went wrong <br><br>You can mail me your queries on below mail ID <br>kumar.vishal437@gmail.com',
                text: '',
                })
         }
       })
  


    }*/
    
  return (
    <div>
        <Slider/>
        <div className="container marketing pt-5 mb-5">
            <div className="row">
                <div className="col-sm-12">
                    <h2 className="text-center  pb-5">WELCOME TO HARIWEB TECHNO</h2>
                    <p>If you are looking to create a website, but don’t know the first thing about CSS, HTML or PHP, you are in the right place. Allow Graceful Web Development to help you create a website that guarantees an online presence for your company. Almost all companies can benefit from having a website. Your website can serve as a place to showcase your skills, knowledge and wisdom regarding your particular business or area of ​​expertise. Your website can quickly become your most important source of contacts and references. With only a fraction of the cost of newspaper and magazine ads, a carefully developed website can bring references, contacts and cheap sales from search engines.</p>
                </div>
            </div>
            </div>
        <div className="marketing pt-5">
        <section className="custom_service" id="custom_service">
        <div className="container">
        
        <div className="row  pt-5">
            <div className="col-sm-12">
            <h2 className="text-center">OUR SERVICES</h2>
            </div>
        </div>
        <div className="row row-cols-1 row-cols-md-3 g-4 mb-5 pt-5">
            {
                services.map((service,i)=>{
                    return(
                        <div className="col">
                            <div className="card h-100">
                            <img src={baseURL+'/' + service.image}  />
                            <div className="card-body">
                                <h5 className="card-title">{service.title}</h5>
                                <p className="card-text">{service.content.length > 200 ? service.content.substring(0, 200) + '...' : Parser(service.content)} </p>
                            
                                <Link className="btn btn-secondary" to={service.page_url}>View details</Link>
                            </div>
                            </div>
                        </div>
                    )
                })
            }
            
           
          
            </div>
            </div>
            </section>

            <section className="page-section bg-light pt-5" id="portfolio">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Portfolio</h2>
                    <h3 className="section-subheading text-muted"></h3>
                </div>
                <div className="row pt-5">

       
                {
                        portfolioList.map((portfolio,i)=>{
                            return(
                                <>
                                <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0">
                       
                                    <div className="portfolio-item">
                                        <a className="portfolio-link" data-bs-toggle="modal" href={'#portfolioModal_'+i}>
                                            <div className="portfolio-hover">
                                                <div className="portfolio-hover-content"><svg className="svg-inline--fa fa-plus fa-3x" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"></path></svg></div>
                                            </div>
                                            <img className="img-fluid" src={baseURL+'/partner/image/'+portfolio.image } alt="..."/>
                                        </a>
                                        <div className="portfolio-caption">
                                            <div className="portfolio-caption-heading">{portfolio.title}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-modal modal fade" id={'portfolioModal_'+i} tabindex="-1" role="dialog" aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="close-modal" data-bs-dismiss="modal"><i className='fa fa-window-close'></i></div>
                                        <div className="container">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-8">
                                                    <div className="modal-body">
                                                        <h2 className="text-uppercase">{portfolio.title}</h2>
                                                       
                                                        <img className="img-fluid d-block mx-auto" src={baseURL+'/partner/image/'+portfolio.image } alt={portfolio.title} />
                                                        
                                                         {(() => {
                                                            if (portfolio.description){
                                                                return (
                                                                    <p>{Parser(portfolio.description) } </p>
                                                                )
                                                            }
                                                            
                                                            return null;
                                                            })()}
                                                       
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                </>
                            )
                        })
                    }
                
                
                    
                </div>
            </div>
        </section>


           
           


           <section className="contact_form pt-5 pb-5">
            <div className="container">

            <div className="row featurette pt-5">
            <div className="col-md-7 order-md-2">
                <h2 className="featurette-heading fw-normal lh-1">DROP US A MESSAGE.</h2><span className="text-muted">What we need from you when we quote each project individually and carefully to protect you..</span>
               
               <form>
                    <div className="row ">
                        <div className="col-sm-6">
                            <div className="form-group pt-3">
                                <input type="text" className="form-control" placeholder="Name" name="name" onChange={inputsHandler} value={inputField.name} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group pt-3">
                                <input type="email" className="form-control"  name="email" placeholder="Email" onChange={inputsHandler} value={inputField.email}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group pt-3">
                                <input type="text" className="form-control" name="phone" placeholder="Phone" onChange={inputsHandler} value={inputField.phone}/>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group pt-3">
                                <input type="text" className="form-control" name="subject"  placeholder="Subject" onChange={inputsHandler} value={inputField.subject}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group  pt-3">
                               <textarea name="message" id="" cols="30" rows="4" className="form-control"   placeholder="Message" onChange={inputsHandler} value={inputField.message}></textarea>
                            </div>
                        </div>
                      
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group pt-3 pb-5">
                              <input type="submit" onClick={submitForm} className="btn btn-info custom_sub_btn"/>
                            </div>
                        </div>
                      
                    </div>
               </form>
            </div>
            <div className="col-md-5 order-md-1">
               <img src="../images/code.png"/>

            </div>
            </div>

            </div>
            </section>
            </div>
    </div>
  )
}
