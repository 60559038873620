import React from 'react'
import { Link } from 'react-router-dom'

function About() {
  return (
    <div className="pt-5 headerBackground">
       <section class="container text-center headerBackground pt-5">
            <div class="row py-lg-5">
            <div class="col-lg-6 col-md-8 mx-auto">
                <h1 class="fw-light">About Us</h1>
                 {/*<p class="lead text-muted">Something short and leading about the collection below—its contents, the creator, etc. Make it short and sweet, but not too short so folks don’t simply skip over it entirely.</p>*/}
            </div>
            </div>
        </section>
        <div class="album py-5 bg-light">
            <div class="container">
                <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div class="col-10 col-sm-8 col-lg-6">
                        <img src="../images/img1.png" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy" />
                    </div>
                    <div class="col-lg-6">
                        <h1 class="display-5 fw-bold lh-1 mb-3">WHO WE ARE</h1>
                        <p class="lead">HariWeb Techno a team of professional web designers and developers – specializes in providing complete e-commerce solutions including web design, development, Internet marketing, web promotion, email marketing and 24 HOUR ONLINE TECHNICAL SUPPORT. We design custom web pages, design personal and commercial websites, program custom web applications, e-commerce websites (eg online shopping cart, product lists, property lists and cars). and other real estate listing websites). Outsourced technical support company, Online customer support company. Email support / live chat support.</p>
                        <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                            
                        <Link className="btn btn-secondary btn-lg px-4 me-md-2" to="/contact-us">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About
