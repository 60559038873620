import React , {useState,useEffect} from 'react'
import { FacebookProvider, Like } from 'react-facebook';
import { Link } from "react-router-dom";
import http from '../http';
import Parser from 'html-react-parser';



export default function Footer() {
  const baseURL="http://localhost/hariwebtechnosite/backend";

    const [services, setServices] = useState([])
      useEffect(()=>{
        getAllServices()
      }, [])
  
      const getAllServices = () => {
        try {
          http.get('/api/v1/serviceList').then(services=>{
            setServices(services.data);
          })
        } catch (error) {
          console.log(error)
        }
  
        
    }
    const [footermenus, setFooterMenus] = useState([])
    useEffect(()=>{
      getAllFooterMenus()
    }, [])

    const getAllFooterMenus = () => {
      try {
        http.get('/api/v1/footerMenuList').then(footermenus=>{
          setFooterMenus(footermenus.data);
        })
      } catch (error) {
        console.log(error)
      }

      
    }

    const [footerlogo, setfooterlogo] = useState([])
    const [footertext, setfootertext] = useState([])
    const [footeremail, setfooteremail] = useState([])
    const [footerphone, setfooterphone] = useState([])
    const [footercopyright, setfootercopyright] = useState([])
    const [facebook_url, setfacebook_url] = useState([])


    const [siteSettings, setsiteSettings] = useState([])
      useEffect(()=>{
        getSiteSetting()
      }, [])
  
      const getSiteSetting = () => {
        try {
          http.get('/api/v1/siteSettings').then(siteSettings=>{
            setsiteSettings(siteSettings.data);
            setfootertext(siteSettings.data.footer_text);
            setfooterlogo(siteSettings.data.image);
            setfooteremail(siteSettings.data.email);
            setfooterphone(siteSettings.data.phone1);
            setfootercopyright(siteSettings.data.copy_right);
            setfacebook_url(siteSettings.data.facebook_url);
          })
        } catch (error) {
          console.log(error)
        }
  
        
    }

  return (
    <div>
      
      <div className="footer">

      
        <footer className="text-center text-lg-start text-white" >
          
          <section className="d-flex justify-content-between p-4">
           
            <div className="me-5">
              <span>Get connected with us on social networks:</span>
            </div>
            <div>
              <a href={facebook_url} className="text-white me-4" target="blank">
                <i className="fa fa-facebook-f"></i>
              </a>
            {/*  <a href="/" className="text-white me-4">
                <i className="fa fa-twitter"></i>
              </a>
              <a href="/" className="text-white me-4">
                <i className="fa fa-google"></i>
              </a>
              <a href="/" className="text-white me-4">
                <i className="fa fa-instagram"></i>
              </a>
              <a href="/" className="text-white me-4">
                <i className="fa fa-linkedin"></i>
              </a>
              <a href="/" className="text-white me-4">
                <i className="fa fa-github"></i>
              </a>*/}
            </div>
         
          </section>
         
          <section className="">
            <div className="container text-center text-md-start mt-5">
            
              <div className="row mt-3">
              
                <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                 
                  <h6 className="text-uppercase fw-bold"> 
                    <img src={baseURL+'/site/image/'+footerlogo } className="w-100" alt="Logo"/>
                    </h6>
                  <hr className="mb-4 mt-0 d-inline-block mx-auto"/>
                  <p>
                 {footertext }
                  </p>
                </div>
                
                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                 
                  <h6 className="text-uppercase fw-bold">Services</h6>
                  <hr  className="mb-4 mt-0 d-inline-block mx-auto"
                     />
                     {
                      services.map((service,i)=>{
                          return(
                            <p>
                            <Link className="text-white" to={service.page_url}>{service.title}</Link>
                            </p>
                             
                          )
                      })
                  }
                  
                 
                </div>
               
                <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                 
                  <h6 className="text-uppercase fw-bold">Useful links</h6>
                  <hr  className="mb-4 mt-0 d-inline-block mx-auto"  />

                  {
                      footermenus.map((footermenu,i)=>{
                          return(
                            <p>
                          <Link className="text-white" to={footermenu.link}>{footermenu.title}</Link>      
                        </p>
                             
                          )
                      })
                  }

               
               
                </div>
               
                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                 
                  <h6 className="text-uppercase fw-bold">Contact</h6>
                  <hr className="mb-4 mt-0 d-inline-block mx-auto" />
                  <p><i className="fa fa-envelope mr-3"></i>&nbsp;&nbsp; {footeremail }</p>
                  <p><i className="fa fa-phone mr-3"></i>&nbsp;&nbsp;{footerphone}</p>
                  <p>
                  <FacebookProvider appId="639758196807211">
                  <Like href="https://www.facebook.com/hariwebtechno/" colorScheme="dark" showFaces share />
                </FacebookProvider>
                  </p>
                </div>
               
              </div>
            
            </div>
          </section>
         
          <div className="text-center p-3 copy_right" >
          {footercopyright }
          </div>
          
        </footer>
      

      </div>
     
     
    </div>
  )
}
